/* eslint-disable react-hooks/exhaustive-deps */
import InputField from "components/fields/InputField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { useColorMode, useToast } from "@chakra-ui/react";

import { Checkbox } from "@chakra-ui/react";
import { signIn } from "../../services/Auth";
import { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import GoogleAuth from "components/googleAuth/GoogleAuth";
import { transactionListApi } from "services/pricing";
import Footer from "components/footer/FooterAuthDefault";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
//"services/auth";
const googleSiteKey = process.env.REACT_APP_RECAPTCHA_KEY;
const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    login_email: "",
    login_password: "",
    recaptcha: "",
  };
  const [recaptchaKey, setRecaptchaKey] = useState(Date.now());
  const searchParams = new URLSearchParams(location?.search.split("?")[1]);
  const affiliate_id = searchParams && searchParams.get("aff");
  useEffect(() => {
    // Update the key when the color mode changes to force re-render
    setRecaptchaKey(Date.now());
  }, [colorMode]);
  const validationSchema = Yup.object().shape({
    login_email: Yup.string()
      .email(t("invalidEmail"))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("invalidEmail"))
      .required(t("requireEmail")),
    login_password: Yup.string().required(t("passwordRequired")),
    recaptcha: Yup.string().required(t("recaptchaRequired")),
  });

  const handleSignIn = (values: any) => {
    setLoading(true);
    let signInPayload = {
      email: values.login_email,
      password: values.login_password,
      affiliate_id: affiliate_id,
    };
    signIn(signInPayload)
      .then((response) => {
        if (response.success) {
          localStorage?.setItem("token", response?.data?.token);
          localStorage?.setItem("fName", response?.data?.first_name);
          localStorage?.setItem("lName", response?.data?.last_name);
          localStorage?.setItem("email", response?.data?.email);
          localStorage?.setItem("amount", response?.data?.amount);
          localStorage?.setItem("steps", response?.data?.steps);
          toast({
            description: t("messages.signIn"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          if (response?.data?.steps === 0) {
            setLoading(false);
            navigate("/auth/onboarding");
          } else {
            fetchTransactionHistory(response?.data?.token);
            setLoading(false);
          }
        } else {
          setLoading(false);
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };
  const token = localStorage.getItem("token");
  const steps = localStorage.getItem("steps");
  useEffect(() => {
    if (token && Number(steps) > 0) {
      navigate("/admin/dashboard");
    }
  }, []);
  const fetchTransactionHistory = (token: string) => {
    transactionListApi({ token })
      .then((response: any) => {
        if (response?.success) {
          if (response?.data) {
            const hasSuccessStatus = response?.data.some(
              (obj: any) => obj.status === "success"
            );
            localStorage.setItem("isTransaction", hasSuccessStatus);
          }
          navigate("/admin/dashboard");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };
  const handleRecaptchaExpire = (setFieldValue: any) => {
    setFieldValue("recaptcha", ""); // Reset reCAPTCHA value
    setTimeout(() => setFieldValue("recaptcha", ""), 0); // Trigger validation
  };
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {loading && <Loader />}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h1 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {t("signInText")}
        </h1>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {t("signInCaption")}
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            handleSignIn(values);

            if (Array.isArray((window as any).dataLayer)) {
              (window as any).dataLayer.push({
                event: "Sign-in",
                buttonName: "Sign-in",
              });
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <InputField
                variant="auth"
                name="login_email"
                id="login_email"
                extra="mb-3"
                // label="Email*"
                label={t("email") + "*"}
                placeholder="mail@yourcompanyname.com"
                type="text"
              />

              <InputField
                variant="auth"
                name="login_password"
                extra="mb-3"
                label={t("password") + "*"}
                placeholder={t("passwordPlaceholder")}
                id="login_password"
                type="password"
              />
              <div className="mb-4 flex items-center justify-between px-2">
                <div className="flex items-center">
                  <Checkbox />
                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    {t("keepLoggedIn")}
                  </p>
                </div>
                <Link to="/auth/forgot-password">
                  {t("forgotPasswordText")}
                </Link>
              </div>
              <div className={`recaptcha-container mb-4 mt-4 ${colorMode}`}>
                <ReCAPTCHA
                  key={recaptchaKey}
                  theme={colorMode}
                  id="recaptcha"
                  sitekey={googleSiteKey}
                  onChange={(value) => {
                    setFieldValue("recaptcha", value);
                  }}
                  onExpired={() => handleRecaptchaExpire(setFieldValue)}
                />
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="error-message"
                />
              </div>
              <button
                type="submit"
                className="linear mt-2 w-full rounded bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
              >
                {t("signInText")}
              </button>
            </Form>
          )}
        </Formik>
        <div className="my-3 text-center">{t("or")}</div>
        <div className="google-btn flex w-full justify-center">
          <GoogleAuth
            setLoading={setLoading}
            login={true}
            affiliate_id={affiliate_id}
          />
        </div>
        <div className="mt-4 flex items-center">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            {t("notRegistered")}
          </span>
          <Link
            to="/auth/sign-up"
            className="ml-1 text-blue-700 dark:text-blue-700"
          >
            {t("createAccount")}
          </Link>
        </div>
        <div className="mt-5 flex items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
