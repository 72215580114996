/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import useRoutes from "routes";
import Footer from "components/footer/Footer";
import { useMediaQuery } from "@chakra-ui/react";
import { JoyrideProvider } from "hooks/JoyrideContext";

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const routes = useRoutes();
  // const [open, setOpen] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [isLargerThanMD] = useMediaQuery("(min-width: 768px)");
  const [currentRoute, setCurrentRoute] = React.useState("Dashboard");
  const [pageRefresh, setPageRefresh] = React.useState(false);

  React.useEffect(() => {
    // window.addEventListener("resize", () =>
    //   window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    // );
  }, []);
  React.useEffect(() => {
    setDocTitle(routes);
    getActiveRoute(routes);
  }, [location.pathname, pageRefresh]);
  const setDocTitle = (routes: any): string | boolean => {
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        document.title = `${routes[i].name} | PreCallAI`;
      }
    }
    return;
  };
  const getActiveRoute = (routes: any): string | boolean => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].submenu) {
        for (let j = 0; j < routes[i].submenuItems.length; j++) {
          if (
            window.location.href.indexOf(
              routes[i].submenuItems[j].layout +
                "/" +
                routes[i].submenuItems[j].path
            ) !== -1
          ) {
            setCurrentRoute(routes[i].submenuItems[j].name);
          }
        }
      } else {
        if (
          window.location.href.indexOf(
            routes[i].layout + "/" + routes[i].path
          ) !== -1
        ) {
          setCurrentRoute(routes[i].name);
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: any): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  // const getRoutes = (routes: any): any => {
  //   return routes.map((prop: any, key: any) => {
  //     if (prop.layout === "/admin") {
  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  const getRoutes = (routes: any[]): any => {
    return routes.flatMap((prop: any, key: number) => {
      if (prop.layout === "/admin") {
        // Main route component
        let mainRoute = (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );

        // Check if there are submenus
        if (prop.submenuItems && prop.submenuItems.length > 0) {
          // Recursive call to get submenus
          let subRoutes = getRoutes(prop.submenuItems);
          // Concatenate main route with subroutes
          return [mainRoute, ...subRoutes];
        } else {
          // No submenus, return only the main route
          return mainRoute;
        }
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const handleSidebarToggle = () => {
    if (isLargerThanMD) {
      setIsOpen(!isOpen);
    } else {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    }
  };
  const handleBackdropClick = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  };
  return (
    <div className="flex h-full w-full">
      {isMobileMenuOpen && !isLargerThanMD && (
        <div
          className="fixed inset-0 z-[99] bg-gray-800 opacity-50"
          onClick={handleBackdropClick}
        ></div>
      )}
      <Sidebar
        isOpen={isOpen}
        setIsOpen={(data: any) => setIsOpen(data)}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={(data: any) => setIsMobileMenuOpen(data)}
        isLargerThanMD={isLargerThanMD}
        handleSidebarToggle={handleSidebarToggle}
      />
      {/* Navbar & Main Content */}
      <div
        className={`h-full w-full flex-1 overflow-auto bg-lightPrimary transition-all duration-300 dark:bg-navy-900 ${
          isOpen && isLargerThanMD
            ? "ml-[270px]"
            : isMobileMenuOpen
            ? "ml-0"
            : "ml-0 md:ml-20"
        }`}
      >
        {/* Main Content */}
        <main className={`mx-[12px] h-full flex-none transition-all`}>
          {/* Routes */}
          <div className="h-full">
            <Navbar
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              handleSidebarToggle={() => handleSidebarToggle()}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84.5vh] sm:p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
              </Routes>
              <JoyrideProvider />
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
