/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "components/Loader/Loader";
import Card from "components/card";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import CallDurationTotal from "components/callDurationTotal";
import { formatDateAmPm } from "utils/utils";
import { getInboundAssitantHistory } from "services/inboundAssitant";
import { Tag, useToast } from "@chakra-ui/react";
import CallTranscribeModal from "components/modal/CallTranscribeModal";
import SearchBar from "./Searchbar";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
import { useTranslation } from "react-i18next";

const InboundAssistantIncomingCalls = () => {
  const audioUrl = process.env.REACT_APP_URL_FOR_AUDIO;
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state || {};
  const [loading, setLoading] = useState(false);
  const [incomingCalls, setIncomingCalls] = useState([]);
  const [incomingCallsAll, setIncomingCallsAll] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callTranscribe, setCallTranscribe] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    fetchInboundHistory();
  }, []);
  const fetchInboundHistory = () => {
    setLoading(true);
    getInboundAssitantHistory(receivedData?.rowData?.inboundId)
      .then((response) => {
        if (response.status === 200) {
          let allData: any = response?.data;
          allData.sort(function (a: any, b: any) {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
          setIncomingCalls(allData);
          setIncomingCallsAll(allData);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const openTranscribeModal = (data: any) => {
    setCallTranscribe(data);
    setIsModalOpen(true);
  };
  const handleCloseTranscribe = () => {
    setIsModalOpen(false);
  };
  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (incomingCallsAll) {
      const filteredData = incomingCallsAll.filter((item: any) => {
        return Object.keys(item).some((key) => {
          const itemValue = item[key];

          if (typeof itemValue === "string") {
            return itemValue.toLowerCase().includes(value);
          }

          if (typeof itemValue === "number") {
            return itemValue.toString().includes(value);
          }

          if (itemValue instanceof Date) {
            return itemValue.toLocaleDateString().includes(value);
          }

          return false;
        });
      });
      setIncomingCalls(filteredData);
    }
  };

  // const doManualCalling = (item: any) => {};
  return (
    <div>
      {loading && <Loader />}
      <div className="mb-2">
        <div className="mb-3 mt-4 flex flex-col items-center justify-between gap-2 md:flex-row">
          <div>
            <span className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-bold dark:text-white">
              {t("incomingCalls.incomingCalls")} ({receivedData?.rowData?.name})
            </span>
          </div>
          <div className="flex flex-col justify-end gap-4 sm:flex-row">
            <SearchBar
              initialData={searchQuery}
              handleFilteredData={handleSearch}
            />
            <button
              onClick={() => navigate("/admin/dialers")}
              className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <FaArrowLeft className="h-4 w-4" />
              {t("goBack")}
            </button>
          </div>
        </div>
      </div>
      <Card extra={"w-full sm:overflow-auto px-6"}>
        <div className="mt-1 overflow-x-auto">
          <table className="responsive-table w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center">
                      {t("incomingCalls.FromNumber")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center">
                      {t("incomingCalls.duration")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center">
                      {t("incomingCalls.calledAt")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center">
                      {t("incomingCalls.recording")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center">
                      {t("incomingCalls.sentiments")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center"></p>
                  </div>
                </th>
                {/* <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center"></p>
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {incomingCalls &&
                incomingCalls.map((op, i) => (
                  <tr key={i}>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      align="center"
                      data-label={t("incomingCalls.fromNumber")}
                    >
                      {op?.from_number}
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      align="center"
                      data-label={t("incomingCalls.duration")}
                    >
                      <CallDurationTotal
                        totalDurationInSeconds={Number(
                          op?.call_duration_in_sec || 0
                        )}
                      />
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      align="center"
                      data-label={t("incomingCalls.calledAt")}
                    >
                      {formatDateAmPm(op.created_at)}
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      align="center"
                      data-label={t("incomingCalls.recording")}
                    >
                      {op?.recording_url ? (
                        <WavePlayer
                          audio={
                            op?.recording_url?.startsWith("http://") ||
                            op?.recording_url?.startsWith("https://")
                              ? op?.recording_url
                              : audioUrl + op?.recording_url
                          }
                          index={i}
                          currentPlayingIndex={currentPlayingIndex}
                          setCurrentPlayingIndex={setCurrentPlayingIndex}
                        />
                      ) : (
                        // <audio
                        //   controls
                        //   onPlay={(e) => {
                        //     const audioElements =
                        //       document.querySelectorAll("audio");
                        //     audioElements.forEach((audio) => {
                        //       if (audio !== e.target) {
                        //         audio.pause();
                        //         audio.currentTime = 0;
                        //       }
                        //     });
                        //   }}
                        // >
                        //   <source
                        //     src={
                        //       op?.recording_url?.startsWith("http://") ||
                        //       op?.recording_url?.startsWith("https://")
                        //         ? op?.recording_url
                        //         : audioUrl + op?.recording_url
                        //     }
                        //     type="audio/mpeg"
                        //   />
                        //   Your browser does not support the audio element.
                        // </audio>
                        "-"
                      )}
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      align="center"
                      data-label={t("incomingCalls.sentiments")}
                    >
                      {op?.call_sentiment ? (
                        <span>
                          {op?.call_sentiment.includes("positive") ? (
                            <Tag variant="solid" colorScheme="green">
                              {t("incomingCalls.positive")}
                            </Tag>
                          ) : op?.call_sentiment.includes("negative") ? (
                            <Tag variant="solid" colorScheme="red">
                              {t("incomingCalls.negative")}
                            </Tag>
                          ) : (
                            <Tag variant="solid" colorScheme="blue">
                              {t("incomingCalls.neutral")}
                            </Tag>
                          )}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      align="center"
                    >
                      {op?.call_transcribe ? (
                        <button
                          onClick={() => openTranscribeModal(op)}
                          className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                        >
                          {t("incomingCalls.callTranscribe")}
                        </button>
                      ) : (
                        "-"
                      )}
                    </td>
                    {/* <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      align="center"
                    >
                      <button
                        onClick={() => doManualCalling(op)}
                        className="flex items-center justify-center gap-1 rounded bg-green-600 px-6 py-2 text-white hover:bg-green-700"
                      >
                        Manual Call
                      </button>
                    </td> */}
                  </tr>
                ))}
              {!loading && incomingCalls.length === 0 && (
                <tr>
                  <td
                    className="min-w-[150px] border-white/0 py-3 pr-4"
                    align="center"
                    colSpan={5}
                  >
                    {t("incomingCalls.noIncomingCalls")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <CallTranscribeModal
            isOpen={isModalOpen}
            handleClose={handleCloseTranscribe}
            rowData={callTranscribe?.call_transcribe}
          />
        </div>
      </Card>
    </div>
  );
};

export default InboundAssistantIncomingCalls;
