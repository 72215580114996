/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, FormLabel } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { timeZones } from "assets/data/timeZone";
import { useTranslation } from "react-i18next";
import calLogo from "../../assets/img/cal-logo.svg";
import highLevelLogo from "../../assets/img/gohighlevel.png";
import { IoMdSettings } from "react-icons/io";
const SimpleBooking = ({
  setBookingData,
  resetAndCloseModal,
  bookingData,
  setActionData,
  setIsAuthenticated,
}: any) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required(t("assistantForm.bookingDateValid")),
    description: Yup.string().required(t("assistantForm.descriptionValid")),
    say: Yup.string().required(t("assistantForm.infoSayValid")),
  });

  const formik = useFormik({
    initialValues: {
      timezone: bookingData?.timezone || "",
      startDate: bookingData?.startDate,
      description: bookingData?.description || "",
      say: bookingData?.say || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      let apiKeyPayload: any = {
        type: "scheduleMeeting",
        meetingType: bookingData?.meetingType,
        timezone:
          bookingData?.meetingType === "openBooking" ? "" : values?.timezone,
        startDate: values?.startDate,
        description: values?.description,
        say: values?.say,
        length: bookingData?.length,
      };
      if (bookingData?.meetingType === "cal.com") {
        apiKeyPayload.api_key = bookingData?.api_key;
        apiKeyPayload.eventId = bookingData?.eventId;
      }
      if (bookingData?.meetingType === "gohighlevel") {
        apiKeyPayload.calenderId = bookingData?.calenderId;
      }
      if (bookingData?.meetingType === "openBooking") {
        apiKeyPayload.startTime = bookingData?.startTime;
        apiKeyPayload.endTime = bookingData?.endTime;
        apiKeyPayload.bookingDays = bookingData?.bookingDays;
      }
      setBookingData((prev: any) => ({
        ...prev,
        timezone: values?.timezone,
        startDate: values?.startDate,
        description: values?.description,
        say: values?.say,
      }));

      setActionData((prev: any) => {
        const existingIndex = prev.findIndex(
          (item: any) => item.type === "scheduleMeeting"
        );
        if (existingIndex !== -1) {
          const newArray = [...prev];
          newArray[existingIndex] = apiKeyPayload;
          return newArray;
        } else {
          return [...prev, apiKeyPayload];
        }
      });

      handleCloseForm();
    },
  });
  const handleCloseForm = () => {
    resetAndCloseModal();
    formik.resetForm();
  };
  useEffect(() => {
    if (bookingData) {
      formik.setValues({
        timezone: bookingData?.timezone || "",
        startDate: bookingData?.startDate,
        description: bookingData?.description || "",
        say: bookingData?.say || "",
      });
    }
  }, [bookingData]);
  return (
    <div>
      <div className="flex items-center justify-end gap-4">
        {bookingData?.meetingType === "gohighlevel" && (
          <img src={highLevelLogo} alt="highlevel" className="w-[100px]" />
        )}
        {bookingData?.meetingType === "cal.com" && (
          <img src={calLogo} alt="cal" className="w-[80px]" />
        )}
        {bookingData?.meetingType === "openBooking" && (
          <div className="text-[18px] font-bold">
            {t("assistantForm.openBooking")}
          </div>
        )}
        <IoMdSettings
          onClick={() => {
            setIsAuthenticated(false);
          }}
          className={`h-6 w-6 cursor-pointer text-blue-700`}
        />
      </div>
      <FormControl mt={4}>{t("assistantForm.noteScheduling")}</FormControl>
      {bookingData?.meetingType !== "openBooking" && (
        <FormControl mt={4}>
          <FormLabel>{t("assistantForm.timeZone")}</FormLabel>
          <select
            id="timezone"
            value={formik.values.timezone}
            onChange={formik.handleChange}
            className="h-[50px] w-full rounded border-[1px] border-gray-200 px-4 
            py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
          >
            {timeZones?.map((timeZone: any, index: number) => (
              <option key={index} value={timeZone}>
                {timeZone}
              </option>
            ))}
          </select>
        </FormControl>
      )}
      <FormControl mt={4}>
        <div className="mb-2 flex items-center gap-2 font-medium">
          {t("assistantForm.bookingDate")}{" "}
        </div>
        <select
          id="startDate"
          value={formik.values.startDate}
          onChange={formik.handleChange}
          className="h-[50px] w-full rounded border-[1px] border-gray-200 px-4 
        py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
        >
          <option value={""}>{t("assistantForm.selectBooking")}</option>
          <option value={"0"}>{t("assistantForm.today")}</option>
          <option value={"1"}>{t("assistantForm.day1")}</option>
          <option value={"2"}>{t("assistantForm.day2")}</option>
          <option value={"3"}>{t("assistantForm.day3")}</option>
          <option value={"4"}>{t("assistantForm.day4")}</option>
          <option value={"5"}>{t("assistantForm.day5")}</option>
        </select>
        {/* <Input
          type="date"
          id="startDate"
          value={formik.values.startDate}
          onChange={formik.handleChange}
          onKeyDown={(e: any) => e.preventDefault()}
          style={{ pointerEvents: "auto" }}
          min={minDate}
          className="datepicker_custom w-full rounded border-[1px] border-gray-200 bg-white 
                px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
        /> */}
        {formik.touched.startDate && formik.errors.startDate ? (
          <div className="text-red-500">
            {formik.errors.startDate.toString()}
          </div>
        ) : null}
      </FormControl>
      <FormControl mt={4}>
        <div className="mb-2 flex items-center gap-2 font-medium">
          {t("assistantForm.description")} *
        </div>
        <input
          className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
          id="description"
          type="text"
          value={formik.values.description}
          onChange={formik.handleChange}
          placeholder={t("assistantForm.meetingDescriptionPlaceholder")}
        />
        {formik.touched.description && formik.errors.description ? (
          <div className="text-red-500">
            {formik.errors.description.toString()}
          </div>
        ) : null}
      </FormControl>
      <FormControl mt={4}>
        <div className="mb-2 flex items-center gap-2 font-medium">
          {t("assistantForm.reachTarget")} *
        </div>
        <input
          className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
          id="say"
          type="text"
          value={formik.values.say}
          onChange={formik.handleChange}
          placeholder={t("assistantForm.scheduleConditionPlaceholder")}
        />
        {formik.touched.say && formik.errors.say ? (
          <div className="text-red-500">{formik.errors.say.toString()}</div>
        ) : null}
      </FormControl>
      <div className="mt-5 flex items-center justify-start">
        <button
          onClick={() => formik.handleSubmit()}
          className={`mr-3 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800 active:bg-blue-800`}
        >
          {t("save")}
        </button>
        <Button onClick={handleCloseForm}>{t("cancel")}</Button>
      </div>
    </div>
  );
};

export default SimpleBooking;
