/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import languages from "assets/data/language.json";
import { Button, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { templateData } from "assets/data/template";

const StepOne = ({ formik, handleNextStep }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const handleNext = () => {
    formik.validateField("company_website").then(() => {
      if (formik.errors.company_website) {
        toast({
          description: t("assistantForm.companyWebsiteValid1"),
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return;
      } else {
        handleNextStep();
      }
    });
  };
  return (
    <div className="m-auto max-w-[900px]">
      <div className="mt-3 flex flex-col gap-6 md:flex-row">
        <div className="relative flex w-full flex-col gap-1 md:w-[50%]">
          <label className="  dark:text-white" htmlFor="type ">
            {t("assistantForm.type")}
          </label>

          <select
            id="type"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            className="h-[50px] rounded border-[1px] border-gray-500 px-4 leading-tight focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
          >
            <option value="inbound">{t("assistantForm.inbound")}</option>
            <option value="outbound">{t("assistantForm.outbound")}</option>
          </select>
          {formik.touched.type && formik.errors.type ? (
            <div className="text-red-500">{formik.errors.type.toString()}</div>
          ) : null}
        </div>
        <div className="relative flex w-full flex-col gap-1 md:w-[50%]">
          <div className="flex flex-col gap-1 rounded-lg">
            <label className="  dark:text-white" htmlFor="Start Speech">
              {t("assistantForm.language")} *
            </label>
            <select
              id="language"
              name="language"
              value={formik.values.language}
              onChange={formik.handleChange}
              className="h-[50px] w-full rounded border-[1px] border-gray-500 px-4 leading-tight focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
            >
              <option value="">{t("assistantForm.selectLanguage")}</option>
              {Object.entries(languages).map(([language, code]) => (
                <option key={code} value={code}>
                  {language}
                </option>
              ))}
            </select>
            {formik.touched.language && formik.errors.language ? (
              <div className="text-red-500">
                {formik.errors.language.toString()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-6 md:flex-row">
          <div className="relative mt-5 flex w-full flex-col gap-1 md:w-[50%]">
            <label className="  dark:text-white" htmlFor="companyName">
              {t("assistantForm.companyNameText")} *
            </label>

            <input
              id="company_name"
              type="text"
              value={formik?.values.company_name}
              className="h-[50px] rounded border border-gray-500 px-4  
            focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
              onChange={(e) =>
                formik.setFieldValue("company_name", e.target.value)
              }
            />
            {formik.touched.company_name && formik.errors.company_name ? (
              <div className="text-red-500">
                {formik.errors.company_name.toString()}
              </div>
            ) : null}
          </div>
          <div className="relative mt-5 flex w-full flex-col gap-1 md:w-[50%]">
            <label className="  dark:text-white" htmlFor="companyName">
              {t("assistantForm.companyWebsite")}
            </label>
            <div className="flex items-center overflow-hidden rounded border border-gray-500">
              <select
                id="prefix"
                name="prefix"
                onChange={formik.handleChange}
                value={formik.values.prefix}
                className="h-[50px]  bg-gray-100 px-3 py-2 dark:bg-navy-900 dark:hover:border-[#fff]"
              >
                <option value="https://">https://</option>
                <option value="http://">http://</option>
              </select>
              <input
                id="company_website"
                name="company_website"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.company_website}
                className="h-[50px] flex-1 px-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
                placeholder="yourwebsite.com"
              />
            </div>
            {/* <input
              id="company_website"
              type="text"
              value={formik?.values.company_website}
              className="h-[50px] rounded border border-gray-500 px-4  
            focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
              onChange={(e) =>
                formik.setFieldValue("company_website", e.target.value)
              }
            /> */}
            {formik.touched.company_website && formik.errors.company_website ? (
              <div className="text-red-500">
                {formik.errors.company_website.toString()}
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-5 flex w-full flex-col gap-1">
          <label className="  dark:text-white" htmlFor="name ">
            {t("assistantForm.agentName")} *
          </label>

          <input
            id="name"
            type="text"
            value={formik?.values.name}
            className="h-[50px] rounded border border-gray-500 px-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500">{formik.errors.name.toString()}</div>
          ) : null}
        </div>
        <div className="mt-5 flex w-full flex-col gap-1">
          <label className="  dark:text-white" htmlFor="Start Speech">
            {t("assistantForm.goalText")} *
          </label>
          <select
            id="goal"
            name="goal"
            value={formik.values.goal}
            onChange={formik.handleChange}
            className="h-[50px] w-full rounded border-[1px] border-gray-500 px-4 leading-tight focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
          >
            <option value="">{t("assistantForm.selectGoal")}</option>
            {templateData.map((item: any, i: any) => (
              <option key={i} value={t(`assistantForm.${item?.name}`)}>
                {t(`assistantForm.${item?.name}`)}
              </option>
            ))}
          </select>
          {formik.touched.goal && formik.errors.goal ? (
            <div className="text-red-500">{formik.errors.goal.toString()}</div>
          ) : null}
        </div>
      </div>
      <div className="mt-8 flex justify-center gap-[20px]">
        <Button
          colorScheme="blue"
          onClick={() => handleNext()}
          className="dark:bg-blue-700 dark:text-white"
          isDisabled={
            !formik.values.type ||
            !formik.values.company_name ||
            !formik.values.name ||
            !formik.values.language ||
            !formik.values.goal
          }
        >
          {t("assistantForm.nextStep")}
        </Button>
      </div>
    </div>
  );
};

export default StepOne;
