import { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { createPhoneNumbers, validateAuth } from "services/phoneNumbers";
import { useToast } from "@chakra-ui/react";
// import PhoneInput from "react-phone-input-2";
// import { useColorMode } from "@chakra-ui/react";
// import { isValidPhoneNumber } from "utils/utils";
import SaveConfirmModal from "./SaveConfirmModal";
import { useTranslation } from "react-i18next";
const PhoneNumberAddModal = ({
  isOpen,
  handleClose,
  setRefresh,
  refresh,
  loading,
}: any) => {
  const toast = useToast();
  // const { colorMode } = useColorMode();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const [isValidate, setIsValidate] = useState(true);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [phoneNumberList, setPhoneNumberList] = useState([]);

  const validationSchema = Yup.object().shape({
    dialerType: Yup.string().required(t("dialers.typeValid")),
    name: Yup.string().required(t("dialers.nameValid")),
    authId: Yup.string().required(t("dialers.authValid")),
    authToken: Yup.string().required(t("dialers.tokenValid")),
    contactNumber: Yup.string().required(t("dialers.phoneValid")),
  });
  const formik = useFormik({
    initialValues: {
      dialerType: "",
      name: "",
      authId: "",
      authToken: "",
      contactNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payloadData = {
        dialer_type: values?.dialerType,
        name: values?.name,
        phone_number: values?.contactNumber,
        sid: values?.authId,
        auth_token: values?.authToken,
      };
      loading(true);
      createPhoneNumbers(payloadData)
        .then((response) => {
          if (response.status === 200) {
            setRefresh(!refresh);
            setIsValidate(false);
            resetAndCloseModal();
            toast({
              description: t("messages.dialerCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            setIsValidate(true);
          }
          loading(false);
        })
        .catch((error) => {
          loading(false);
          console.error("Error submitting feedback:", error);
        });
    },
  });

  const resetAndCloseModal = () => {
    formik.resetForm();
    setPhoneNumberList([]);
    handleClose();
  };
  const validateAuthId = () => {
    if (
      formik?.values?.dialerType &&
      formik?.values?.authId &&
      formik?.values?.authToken
    ) {
      const payloadData = {
        dialer_type: formik?.values?.dialerType,
        sid: formik?.values?.authId,
        auth_token: formik?.values?.authToken,
      };
      loading(true);
      validateAuth(payloadData)
        .then((response) => {
          if (response.success) {
            setRefresh(!refresh);
            toast({
              description: response?.message,
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            setIsValidate(false);
            setPhoneNumberList(response?.data);
          } else {
            toast({
              description: response?.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            setPhoneNumberList([]);
            formik.setFieldValue("contactNumber", "");
          }
          loading(false);
        })
        .catch((error) => {
          loading(false);
          console.error("Error submitting feedback:", error);
        });
    }
  };
  const checkIsDirtyForm = () => {
    let isDirty = formik.dirty && formik.submitCount === 0;
    if (isDirty) {
      openWarningModal();
    } else {
      resetAndCloseModal();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    resetAndCloseModal();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        blockScrollOnMount={false}
        size={"sm"}
      >
        <DrawerOverlay />
        <DrawerContent className="dark:bg-navy-900">
          <DrawerHeader>{t("dialers.addNew")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl>
              <FormLabel>{t("dialers.type")} *</FormLabel>
              <select
                className="mb-3 w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="dialerType"
                value={formik.values.dialerType}
                onChange={formik.handleChange}
              >
                <option value="">{t("assistantForm.selectBooking")}</option>
                <option value="Twilio">Twilio</option>
              </select>
              {formik.touched.dialerType && formik.errors.dialerType ? (
                <div className="text-red-500">
                  {formik.errors.dialerType.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl>
              <FormLabel>{t("dialers.name")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500">
                  {formik.errors.name.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("dialers.authSid")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="authId"
                type="text"
                value={formik.values.authId}
                onChange={formik.handleChange}
                onBlur={() => setIsValidate(true)}
              />
              {formik.touched.authId && formik.errors.authId ? (
                <div className="text-red-500">
                  {formik.errors.authId.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("dialers.authToken")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="authToken"
                type="text"
                value={formik.values.authToken}
                onChange={formik.handleChange}
                onBlur={() => setIsValidate(true)}
              />
              {formik.touched.authToken && formik.errors.authToken ? (
                <div className="text-red-500">
                  {formik.errors.authToken.toString()}
                </div>
              ) : null}
            </FormControl>
            <div className="flex justify-end">
              <Button
                className="mt-3 h-5 cursor-pointer rounded border border-blue-700 px-4 text-blue-700"
                isDisabled={
                  !formik?.values?.dialerType ||
                  !formik?.values?.authId ||
                  !formik?.values?.authToken
                }
                onClick={validateAuthId}
              >
                {t("dialers.validate")}
              </Button>
            </div>
            <FormControl mt={4}>
              <FormLabel>{t("dialers.phoneNumber")} *</FormLabel>
              {/* <PhoneInput
                inputStyle={{
                  background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                  color: colorMode === "dark" ? "white" : "black",
                }}
                dropdownStyle={{
                  background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                  color: colorMode === "dark" ? "white" : "black",
                }}
                inputProps={{
                  name: "contactNumber",
                  id: "contactNumber",
                  required: true,
                  className:
                    "pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] max-w-[500px] items-center gap-x-2 rounded border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900",
                }}
                buttonStyle={{
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
                country={"us"}
                onChange={(value, country, event, formattedValue) => {
                  let myPhone = convertToE164(formattedValue);
                  formik.setFieldValue("contactNumber", myPhone);
                }}
                onBlur={formik.handleBlur}
              /> */}
              <select
                name="contactNumber"
                id="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                <option value="">{t("dialers.selectPhoneNumber")}</option>
                {phoneNumberList.map((op, i) => (
                  <option key={i} value={op.phoneNumber}>
                    {op.phoneNumber}
                  </option>
                ))}
              </select>
              {formik.touched.contactNumber && formik.errors.contactNumber ? (
                <div className="text-[red]">
                  {formik.errors.contactNumber.toString()}
                </div>
              ) : null}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
              isDisabled={isValidate}
            >
              {t("save")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default PhoneNumberAddModal;
