/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Card from "components/card";
import { MdPermContactCalendar } from "react-icons/md";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import TooltipHorizon from "components/tooltip";
import { numberWithCommasAndDollar, responseHendler } from "utils/utils";
import { getAllCompanyList } from "services/crm";
import { useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import SearchBarInput from "views/admin/assitstants/assistanceList/components/SearchbarInput";
import { useTranslation } from "react-i18next";

type RowObj = {
  company_name: string;
  fullName: string;
  segment_name: string;
  domain: string;
  industry: string;
  number_of_employees: any;
  annual_revenue: any;
  description: any;
  action: any;
};

const Companies = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [sorting, setSorting] = useState<SortingState>([]);
  const [companyList, setCompanyList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const fetchData = useCallback(() => {
    const params = {
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      search: searchQuery,
    };
    fetchCompanyList(params);
  }, [pagination?.pageIndex, pagination?.pageSize, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const fetchCompanyList = (params: any) => {
    setIsLoading(true);
    getAllCompanyList(params)
      .then((response) => {
        setIsLoading(false);
        responseHendler(response);
        if (response.success) {
          if (response?.data) {
            setCompanyList(response?.data);
          } else {
            setCompanyList([]);
          }
          setTotalPages(response?.totalPages || 0);
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const handleSearch = (value: any) => {
    setSearchQuery(value);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };
  const CustomHeader = ({ headerText }: any) => (
    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
      {headerText}
    </p>
  );
  const columns = [
    columnHelper.accessor("company_name", {
      id: "Company Name",
      header: () => (
        <CustomHeader headerText={t("contactCompanies.companyName")} />
      ),
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("domain", {
      id: "Domain",
      header: () => <CustomHeader headerText={t("contactCompanies.domain")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("industry", {
      id: "Industry",
      header: () => (
        <CustomHeader headerText={t("contactCompanies.industry")} />
      ),
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("number_of_employees", {
      id: "Employees",
      header: () => (
        <CustomHeader headerText={t("contactCompanies.employees")} />
      ),
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("annual_revenue", {
      id: "Annual Revenue",
      header: () => (
        <CustomHeader headerText={t("contactCompanies.annualRevenue")} />
      ),
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {numberWithCommasAndDollar(info.getValue())}
        </p>
      ),
    }),
    columnHelper.accessor("description", {
      id: "Description",
      header: () => (
        <CustomHeader headerText={t("contactCompanies.description")} />
      ),
      cell: (info: any) => (
        <TooltipHorizon
          trigger={
            <div className="m-auto max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap sm:justify-center md:text-center">
              {info.getValue()}
            </div>
          }
          placement="top"
          content={info.getValue()}
          extra="rounded text-[11px]"
        />
      ),
    }),
  ];
  const table = useReactTable({
    data: companyList,
    columns: columns,
    state: {
      sorting,
      pagination,
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    // manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  return (
    <div className="sixth-step relative">
      {isLoading && <Loader />}
      <div className="mb-2">
        <div className="mt-2 flex justify-end gap-4 py-2">
          <SearchBarInput
            initialData={searchQuery}
            handleFilteredData={handleSearch}
          />
        </div>
      </div>

      {companyList?.length === 0 && !isLoading ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center py-16">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPermContactCalendar />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("contactCompanies.noCompanies")}
            </h4>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto">
            <table className="responsive-table w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          scope="col"
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {/* {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-3  pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })} */}
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const columnHeader = cell.column.id;
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={columnHeader}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </div>
  );
};

export default Companies;
const columnHelper = createColumnHelper<RowObj>();
