/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import { playGroundCall } from "services/playground";
import { convertToE164, isValidPhoneNumber } from "utils/utils";
import Loader from "components/Loader/Loader";
import { Trans, useTranslation } from "react-i18next";

const AssistantModal = ({
  isOpen,
  handleClose,
  setShowInput,
  showInput,
  assistantSelectedData,
}: any) => {
  const initialRef = useRef(null);
  const { t } = useTranslation();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { colorMode } = useColorMode();
  let fName = localStorage.getItem("fName");
  let lName = localStorage.getItem("lName");
  let email = localStorage.getItem("email");

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("assistantForm.fNameValid")),
    lastName: Yup.string().required(t("assistantForm.lNameValid")),
    email: Yup.string()
      .email(t("assistantForm.emailValid1"))
      .required(t("assistantForm.emailValid")),
    contactNumber: Yup.string()
      .required("Phone number is required")
      .test("valid-phone", "Invalid phone number format", (value) => {
        return isValidPhoneNumber(value);
      }),
  });
  const userPhone = localStorage?.getItem("phone");

  // Formik hook for form management
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      // let ourAction = assistantSelectedData?.actions;
      // let playgroundAct = [];
      // if (ourAction && ourAction.length > 0) {
      //   for (let item of ourAction) {
      //     if (item.type === "sendMessage" || item.type === "transferCall") {
      //       playgroundAct.push(item);
      //     }
      //   }
      // }
      // let act: any =assistantSelectedData?.actions;
      const payload = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        assistant_id: assistantSelectedData?.id,
        phone_number: [values.contactNumber],
        dialer_id: assistantSelectedData?.dialer_id,
      };
      playGroundCall(payload)
        .then((response) => {
          setLoading(false);
          if (response?.success) {
            handleClose();
            toast({
              description: response?.message,
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            handleClose();
            setLoading(false);
          }
        })
        .catch((error) => {
          handleClose();
          console.error("Error:", error);
          setLoading(false);
        });
    },
  });

  const handleDemoCallClick = () => {
    setLoading(true);
    const payload = {
      first_name: fName,
      last_name: lName,
      email: email,
      assistant_id: assistantSelectedData?.id,
      phone_number: [userPhone],
      dialer_id: assistantSelectedData?.dialer_id,
    };
    playGroundCall(payload)
      .then((response) => {
        if (response?.success) {
          setLoading(false);
          handleClose();
          toast({
            description: response?.message,
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          handleClose();
          setLoading(false);
        }
      })
      .catch((error) => {
        handleClose();
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"lg"}
      >
        {loading && <Loader />}
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalHeader className="text-blue-700">
            {t("assistantForm.talkToAssistant")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {showInput ? (
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-1 rounded-lg">
                  <label className="  dark:text-white" htmlFor="Start Speech">
                    {t("assistantForm.fName")} *
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    value={formik?.values.firstName}
                    onChange={formik.handleChange}
                    className="h-full w-full rounded border border-gray-500 p-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-red-500">
                      {formik.errors.firstName.toString()}
                    </div>
                  ) : null}
                </div>
                <div className="mt-4 flex flex-col gap-1 rounded-lg">
                  <label className="  dark:text-white" htmlFor="Start Speech">
                    {t("assistantForm.lName")} *
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    value={formik?.values.lastName}
                    onChange={formik.handleChange}
                    className="h-full w-full rounded border border-gray-500 p-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-red-500">
                      {formik.errors.lastName.toString()}
                    </div>
                  ) : null}
                </div>
                <div className="mt-4 flex flex-col gap-1 rounded-lg">
                  <label className="  dark:text-white" htmlFor="Start Speech">
                    {t("assistantForm.email")} *
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formik?.values.email}
                    onChange={formik.handleChange}
                    className="h-full w-full rounded border border-gray-500 p-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500">
                      {formik.errors.email.toString()}
                    </div>
                  ) : null}
                </div>
                <div className="mt-4 flex flex-col gap-1">
                  <label className="ml-1" htmlFor="name">
                    {t("assistantForm.phoneNumber")}
                  </label>
                  <PhoneInput
                    inputStyle={{
                      background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                      color: colorMode === "dark" ? "white" : "black",
                    }}
                    dropdownStyle={{
                      background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                      color: colorMode === "dark" ? "white" : "black",
                    }}
                    inputProps={{
                      name: "phone",
                      required: true,
                      className:
                        "h-[55px] pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] max-w-[500px] items-center gap-x-2 rounded border border-gray-500 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-500 focus:bg-white focus:outline-none",
                    }}
                    buttonStyle={{
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                    country={"us"}
                    onBlur={formik.handleBlur}
                    onChange={(value, country, event, formattedValue) => {
                      let myPhone = convertToE164(formattedValue);
                      formik.setFieldValue("contactNumber", myPhone);
                    }}
                  />
                </div>
                {formik.touched.contactNumber && formik.errors.contactNumber ? (
                  <div className="text-red-500">
                    {formik.errors.contactNumber}
                  </div>
                ) : null}
              </form>
            ) : (
              <p>
                <Trans
                  i18nKey="assistantForm.verificationMessage"
                  values={{
                    userPhone: userPhone?.includes("+")
                      ? userPhone
                      : `+${userPhone}`,
                    firstName: fName,
                    lastName: lName,
                    email: email,
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
            )}
          </ModalBody>

          <ModalFooter>
            <div className="flex w-full items-center justify-center gap-3">
              {showInput ? (
                <button
                  onClick={() => formik.handleSubmit()}
                  className="linear flex w-fit items-center justify-center gap-2 rounded bg-blue-700 px-[33px] py-[4px] text-base font-medium uppercase text-white transition duration-200 hover:bg-blue-800 dark:bg-blue-700"
                >
                  {t("save")}
                </button>
              ) : (
                <>
                  <button
                    onClick={() => setShowInput(true)}
                    className="linear flex w-fit items-center justify-center gap-2 rounded border border-blue-700 bg-[#fffff] px-[16px] py-[6px] text-base font-normal text-blue-700 transition duration-200"
                  >
                    {t("assistantForm.testCall")}
                  </button>
                  <button
                    onClick={handleDemoCallClick}
                    className="linear flex w-fit items-center justify-center gap-2 rounded bg-blue-700 px-[16px] py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    {t("assistantForm.demoCall")}
                  </button>
                </>
              )}
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssistantModal;
