import React, { useState } from "react";
import PlayGroundHistory from "./PlayGroundHistory";
import Playground from "./PlayGround";
import { useTranslation } from "react-i18next";

const CallPlayGround = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("playground");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);

    // gtm
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: tab,
        buttonName: tab,
      });
    }
  };

  return (
    <div className="eighth-step relative mt-8 w-full pr-0">
      <div className="mb-3 mt-4 flex items-center justify-center gap-3">
        <div>
          <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
            <ul
              className="-mb-px flex flex-wrap gap-5 text-center text-sm font-medium"
              role="tablist"
            >
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-1 ${
                    activeTab === "playground"
                      ? "border-b-2 border-blue-700 text-blue-700"
                      : " text-gray-600"
                  }`}
                  onClick={() => handleTabClick("playground")}
                  type="button"
                  role="tab"
                  aria-controls="styled-dashboard"
                  aria-selected={activeTab === "playground"}
                >
                  <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                    {t("playground.playground")}
                  </div>
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-1 ${
                    activeTab === "playground-history"
                      ? "border-b-2 border-blue-700 text-blue-700"
                      : " text-gray-600"
                  }`}
                  onClick={() => handleTabClick("playground-history")}
                  type="button"
                  role="tab"
                  aria-controls="styled-profile"
                  aria-selected={activeTab === "playground-history"}
                >
                  <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                    {t("playground.playgroundHistory")}
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {activeTab === "playground" ? <Playground /> : <PlayGroundHistory />}
    </div>
  );
};

export default CallPlayGround;
