/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Card from "components/card";
import { MdEdit, MdDelete } from "react-icons/md";
import ConfirmModal from "components/modal/ConfirmModal";
import SearchBar from "views/admin/default/components/Searchbar";
import Loader from "components/Loader/Loader";
import Pagination from "components/pagination";
import { formatDateAmPm, responseHendler } from "utils/utils";
import { SiKnowledgebase } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import {
  deleteKnowledgeBase,
  getKnowledgeBaseList,
} from "services/knowledgeBase";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
const KnowledgeBaseList = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [isRequest, setIsRequest] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableDataAll, setTableDataAll] = useState<any>([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTableData = tableData?.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    getKnowledgeBaseList()
      .then((response) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.status === 200) {
          if (response?.data) {
            let newData = preprocessQuestionsAnswers(response?.data);
            setTableData(newData);
            setTableDataAll(newData);
            setCurrentPage(1);
          }
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  }, [refresh]);
  const preprocessQuestionsAnswers = (inputArray: any[]) => {
    return inputArray.map((item: any) => {
      // Check if 'data' exists and is a string (HTML content)
      try {
        let qData = JSON.parse(item.data);
        return {
          ...item,
          data: qData,
        };
      } catch (error) {
        const cleanData = item.data.replace(/<\/?[^>]+(>|$)/g, "");

        // Return object with 'data' as an array with question and answer
        return {
          ...item,
          data: [
            {
              question: cleanData,
              answer: cleanData, // Assuming question and answer should be the same
            },
          ],
        };
      }
    });
  };
  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (tableDataAll) {
      const filteredData = tableDataAll.filter(
        (item: any) =>
          typeof item?.title === "string" &&
          item?.title?.toLowerCase()?.includes(value.toLowerCase())
      );
      setTableData(filteredData);
    }
  };
  const handleEdit = (rowData: any) => {
    setRowData(rowData);
    navigate("/admin/knowledge-base/update-knowledge", {
      state: { formData: rowData },
    });
  };

  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModal = (rowData: any) => {
    setRowData(rowData);
    setIsConfirmModalOpen(true);
    setIsConfirm(false);
  };

  useEffect(() => {
    if (isConfirm === true) {
      deleteKnowledgeBase(rowData?.hash_id)
        .then((response) => {
          if (response.success) {
            handleConfirmClose();
            setIsRequest(false);
            setRefresh(!refresh);
            toast({
              description: t("messages.knowBaseDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isRequest && <Loader />}
      <div className="mb-2">
        <div className="mt-2 flex justify-end gap-4 py-2">
          <SearchBar
            initialData={searchQuery}
            handleFilteredData={handleSearch}
          />
          <button
            onClick={() => navigate("/admin/knowledge-base/create-knowledge")}
            className="rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            {t("knowledgeBase.newKnowledgeBase")}
          </button>
        </div>
      </div>
      {currentTableData && currentTableData?.length === 0 && !isRequest ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <SiKnowledgebase />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("knowledgeBase.noknowledgeBase")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("knowledgeBase.noknowledgeBase")}
            </p>
            <button
              onClick={() => navigate("/admin/knowledge-base/create-knowledge")}
              className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <GoPlus className="h-7 w-7" />
              {t("knowledgeBase.getStarted")}
            </button>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto pb-4">
            <table className="responsive-table w-full">
              <thead>
                <tr className="!border-px !border-gray-400">
                  <th
                    scope="col"
                    className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  >
                    {" "}
                    <div className="items-center justify-between text-xs text-gray-200">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                        {t("knowledgeBase.title")}
                      </p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  >
                    {" "}
                    <div className="items-center justify-between text-xs text-gray-200">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                        {t("knowledgeBase.createdAt")}
                      </p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  >
                    {" "}
                    <div className="items-center justify-between text-xs text-gray-200">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                        {t("knowledgeBase.actions")}
                      </p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTableData &&
                  currentTableData?.map((item: any, index) => (
                    <tr key={index}>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("knowledgeBase.title")}
                      >
                        <p className="flex items-center justify-end gap-2 sm:justify-center">
                          {item?.title}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("knowledgeBase.createdAt")}
                      >
                        <p className="flex items-center justify-end gap-2 sm:justify-center">
                          {formatDateAmPm(item?.created_at)}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={t("knowledgeBase.actions")}
                      >
                        <div className="flex items-center justify-end gap-4 sm:justify-center">
                          <MdEdit
                            onClick={() => handleEdit(item)}
                            color="gray"
                            className="h-6 w-6 cursor-pointer"
                          />
                          <MdDelete
                            onClick={() => handleConfirmModal(item)}
                            color="gray"
                            className="h-6 w-6 cursor-pointer"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination
              perPage={perPage}
              allTotal={tableData?.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </Card>
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleClose={handleConfirmClose}
        setIsConfirm={setIsConfirm}
        type={t("knowledgeBase.knowledgeBase")}
      />
    </div>
  );
};

export default KnowledgeBaseList;
