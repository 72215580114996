import Card from "components/card";
import { useEffect, useState } from "react";
import { Box, Tag, useToast } from "@chakra-ui/react";
import AddAmount from "./AddAmount";
import { invoiceDownloadApi, transactionListApi } from "services/pricing";
import Loader from "components/Loader/Loader";
import TooltipHorizon from "components/tooltip";
import Pagination from "components/pagination";
import { MdOutlineHistory } from "react-icons/md";
import { profileDetail } from "services/Auth";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { useTranslation } from "react-i18next";
const Price = () => {
  // const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [date, setDate] = useState<any>(0);
  const toast = useToast();
  const { t } = useTranslation();
  // const [downloadType, setDownloadType] = useState("PDF");
  const [addAmount, setAddAmount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transactionData, setTransactionData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [amount, setAmount] = useState("");

  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTransaction = transactionData?.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });
  const handleSortByDate = () => {
    let direction = "asc";
    if (sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: "created_at", direction });

    const sortedData = [...transactionData].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (dateA < dateB) {
        return direction === "asc" ? -1 : 1;
      }
      if (dateA > dateB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setTransactionData(sortedData);
  };
  // const handleDropdownToggle = (dropdownName: string) => {
  //   setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  // };
  const handleDownload = async () => {
    setLoading(true);
    let monthNumber = Number(date) + 1;
    invoiceDownloadApi(monthNumber)
      .then((response) => {
        if (response?.success) {
          if (response?.path) {
            window.open(response.path, "_blank");
          } else {
            toast({
              description: response.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };

  const handleAddAmount = () => {
    setAddAmount(true);
  };
  // const amount: any = localStorage.getItem("amount");

  useEffect(() => {
    setLoading(true);
    transactionListApi({ token: "" })
      .then((response) => {
        if (response?.success) {
          const hasSuccessStatus = response?.data.some(
            (obj: any) => obj.status === "success"
          );
          localStorage.setItem("isTransaction", hasSuccessStatus);
          const sortedData = response?.data.sort((a: any, b: any) => {
            const dateA: any = new Date(a.created_at);
            const dateB: any = new Date(b.created_at);
            return dateB - dateA;
          });

          setTransactionData(sortedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
    profileDetail()
      .then((response) => {
        if (response?.success) {
          setAmount(response?.data?.[0]?.amount);
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  }, []);

  const monthNames = [
    t("billing.months.January"),
    t("billing.months.February"),
    t("billing.months.March"),
    t("billing.months.April"),
    t("billing.months.May"),
    t("billing.months.June"),
    t("billing.months.July"),
    t("billing.months.August"),
    t("billing.months.September"),
    t("billing.months.October"),
    t("billing.months.November"),
    t("billing.months.December"),
  ];

  const dummayData = [
    {
      type_call: "Playground calls",
      price_per_minute: "0.12",
    },
    {
      type_call: "Campaign calls", // (Normal)
      price_per_minute: "0.10",
    },
    // {
    //   type_call: "Campaign calls (Premium)",
    //   price_per_minute: "0.20",
    // },
  ];
  return (
    <div className="third-step relative flex w-full flex-col gap-5">
      {loading && <Loader />}
      {!addAmount ? (
        <div className="mt-3 flex h-fit w-full flex-col gap-5 xxl:flex-row">
          <div className="w-full lg:col-span-6 lg:!mb-0">
            <Card extra={"w-full h-full p-[24px] bg-cover"}>
              <div className="text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
                {t("billing.billingInformation")}
              </div>
              <div className="mt-12 flex flex-wrap justify-between gap-4">
                <div className="flex flex-col">
                  <div className="text-[20px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
                    {t("billing.currentBalance")}
                  </div>

                  <div className="mt-2 text-[26px] font-bold capitalize">
                    {/* ${amount !== "null" ? Number(amount).toFixed(2) : 0.0} */}
                    {amount !== "null" || amount !== null ? (
                      <span>
                        {Number(amount) < 0 ? (
                          <span className="text-[#d45b5b]">
                            ${Number(amount).toFixed(2)}
                          </span>
                        ) : (
                          <span className="text-[#5bd45b]">
                            ${Number(amount).toFixed(2)}
                          </span>
                        )}
                      </span>
                    ) : (
                      <span className="text-[#5bd45b]">$0.0</span>
                    )}
                  </div>
                  <button
                    onClick={handleAddAmount}
                    className="linear mt-5 w-full rounded bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                  >
                    {t("billing.addFund")}
                  </button>
                </div>
                <div className="flex flex-col">
                  <div className="mb-4 text-[20px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
                    {t("billing.invoice")}
                  </div>
                  <div className="my-4 flex flex-row items-center gap-2 text-[24px] font-medium">
                    <select
                      id="industry_id"
                      name="industry_id"
                      onChange={(e) => setDate(e.target.value)}
                      className="w-full rounded border-[1px] border-gray-200 px-4 py-2 text-[18px] leading-tight  focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    >
                      {monthNames?.map((month: any, index: number) => (
                        <option key={index} value={index}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <span
                      onClick={handleDownload}
                      className="flex cursor-pointer gap-2 rounded border border-blue-700 bg-white px-3 py-1 text-[20px] text-blue-700 dark:bg-navy-900"
                    >
                      {t("billing.download")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <Card extra={"w-full h-full p-[16px] bg-cover"}>
                  <div className="text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
                    {t("billing.pricing")}
                  </div>
                  <Box p={4} pt={6}>
                    <table className="w-full">
                      <thead className="border-b-2">
                        <tr>
                          <th>
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                              {t("billing.callType")}
                            </p>
                          </th>
                          <th>
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600  dark:text-white">
                              {t("billing.pricePerM")}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dummayData.length &&
                          dummayData.map((item: any, index: number) => (
                            <tr key={index}>
                              <td className="p-4">
                                <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                                  {item.type_call}
                                </p>
                              </td>
                              <td className="p-4">
                                <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                                  $ {item?.price_per_minute}
                                </p>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Box>
                </Card>
              </div>
            </Card>
          </div>
          <div className="w-full lg:col-span-6 lg:!mb-0">
            <Card extra={"w-full h-full p-[16px] bg-cover"}>
              <div className="text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
                {t("billing.transactionHistory")}
              </div>
              <Box p={4} pt={6}>
                <table className="responsive-table w-full">
                  <thead className="border-b-2">
                    <tr>
                      <th scope="col">
                        {" "}
                        <p
                          className="flex cursor-pointer items-center justify-center text-sm font-bold text-gray-600 dark:text-white"
                          onClick={handleSortByDate}
                        >
                          {t("billing.date")}
                          {sortConfig.key === "created_at" && (
                            <span>
                              {sortConfig.direction === "asc" ? (
                                <IoIosArrowRoundUp className="h-6 w-6" />
                              ) : (
                                <IoIosArrowRoundDown className="h-6 w-6" />
                              )}
                            </span>
                          )}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="flex items-center justify-center text-sm font-bold text-gray-600  dark:text-white">
                          {t("billing.amount")}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                          {t("billing.transactionId")}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                          {t("billing.status")}
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTransaction.length ? (
                      currentTransaction.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="p-4" data-label="Date">
                            {" "}
                            <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                              {new Date(item.created_at).toLocaleDateString(
                                "en-GB"
                              )}
                            </p>
                          </td>
                          <td className="p-4" data-label="Amount">
                            {" "}
                            <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                              $ {item?.amount}
                            </p>
                          </td>
                          <td className="p-4" data-label="Transaction id">
                            {" "}
                            <TooltipHorizon
                              trigger={
                                <p className="m-auto max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold text-navy-700 dark:text-white">
                                  {item?.transaction_id}
                                </p>
                              }
                              placement="top"
                              content={item?.transaction_id}
                              extra="rounded-full text-[11px]"
                            />
                          </td>
                          <td className="p-4" data-label="Status">
                            {" "}
                            <p className="flex items-center justify-center gap-4 rounded-full font-semibold capitalize text-[#000]">
                              {item?.status === "success" && (
                                <Tag variant="solid" colorScheme="green">
                                  {item?.status}
                                </Tag>
                              )}
                              {item?.status === "pending" && (
                                <Tag variant="solid" colorScheme="yellow">
                                  {item?.status}
                                </Tag>
                              )}
                              {item?.status === "error" && (
                                <Tag variant="solid" colorScheme="red">
                                  {item?.status}
                                </Tag>
                              )}
                              {item?.status === "cancelled" && (
                                <Tag variant="solid">{item?.status}</Tag>
                              )}
                              {!item?.status && <span>-</span>}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-4" colSpan={4}>
                          <div className="col-span-3 mb-auto flex flex-col items-center justify-center">
                            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                              <MdOutlineHistory />
                            </div>
                            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                              {t("billing.noTransaction")}
                            </h4>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  perPage={perPage}
                  allTotal={transactionData?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </Box>
            </Card>
          </div>
        </div>
      ) : (
        <AddAmount setAddAmount={setAddAmount} />
      )}
    </div>
  );
};

export default Price;
